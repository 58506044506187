// IMPORTS
@import (reference) "settings.less";


// FOOTER
#footer {
    position: relative;

    &:before {
        .hr-after();
        top: 0;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 72px 0;
    gap: 20px;
    position: relative;
    color: @sec-color;

    &:after {
        .hr-after();
        bottom: 0;
    }
}

.footer-item {

    > strong {
        color: @header-color;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.2em;
        margin-bottom: 16px;
        display: block;
    }

    &.submenu {

        ul {
            columns: 2;
            column-gap: 70px;
        }

        a {
            display: inline-block;
            padding: 8px 0;
            line-height: 21px;
            color: @sec-color;

            &:hover {
                color: @header-color;
            }
        }
    }
}
.footer-item.contact {

    @media (min-width: 1280px) {
        max-width: 33%;
    }

    a {
        color: @sec-color;

        &:hover {
            color: @header-color;
        }
    }

    .contact-wrapper {
        .grid();
        grid-template-columns: repeat(2, auto);
        gap: 16px;
        justify-items: start;
        padding-top: 8px;

        > * {
            position: relative;
            padding-left: 34px;

            &:before {
                .small-icon();
                position: absolute;
                color: @header-color;
                left: 0;
                top: 0;
            }
        }
    }

    .contact-location {
        grid-column: 1 / 3;

        b {
            display: block;
        }

        span {
            display: inline-block;

            &:first-of-type:after {
                content: ',';
            }

            &:empty,
            &:last-of-type {

                &:after {
                    display: none !important;
                }
            }
        }

        &:before {
            content: "\e913";
        }
    }

    .phone {

        &:before {
            content: "\e914";
        }
    }

    .email {

        &:before {
            content: "\e915";
        }
    }
}

.footer-bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 32px 0 52px 0;

    a {
        color: @sec-color;

        &:hover {
            color: @header-color;
        }
    }

    > span {
        color: @sec-color;
    }
}